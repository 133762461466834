import React, { useRef, useEffect } from 'react'
import ContextConsumer from 'src/layouts/Context.js'
import styled from 'styled-components'
import PinCustom from 'src/assets/images/map-pin.png'
import mediaQuery from 'src/assets/styles/mediaQuery'

import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps'

const myStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ff3d00',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'landscape.natural.landcover',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ff3d00',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1b1b1b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ff3d00',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#373737',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ff3d00',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ff3d00',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ff3d00',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ff3d00',
      },
    ],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ff3d00',
      },
      {
        visibility: 'on',
      },
      {
        weight: 3.5,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ff3d00',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ff0000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
]

const MainWrapper = styled.div`
  width: 100%;
  height: 650rem;
  position: relative;
  z-index: 2;
  /* a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"]
  {
    display: none !important;
  } */
  .gm-bundled-control .gmnoprint {
    display: block;
  }
  .gmnoprint:not(.gm-bundled-control) {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 40vw;
    z-index: 0;
  }
`

function Map(localizations) {
  const localizationsN = localizations.localizations.localizations

  const mapRef = useRef(null)

  // Fit bounds function

  useEffect(() => {
    const fitBounds = () => {
      const bounds = new window.google.maps.LatLngBounds()
      localizationsN.edges.forEach(document => {
        bounds.extend(document.node)
      })
      mapRef.current.fitBounds(bounds)
    }
    fitBounds()
  }, [localizationsN])

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <GoogleMap
          ref={mapRef}
          defaultZoom={10}
          defaultOptions={{
            styles: myStyles,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false,
          }}
          defaultCenter={{
            lat: localizationsN.edges[0].node.lat,
            lng: localizationsN.edges[0].node.lng,
          }}
          //   defaultOptions={{ styles: mapStyles }}
        >
          {localizationsN.edges.map((document, index) => (
            <>
              <Marker
                key={index}
                position={{
                  lat: document.node.lat,
                  lng: document.node.lng,
                }}
                icon={{
                  scaledSize: { width: 40, height: 40 },
                  url: PinCustom,
                }}
              />
            </>
          ))}
        </GoogleMap>
      )}
    </ContextConsumer>
  )
}

const MapWrapped = withScriptjs(withGoogleMap(Map))

export default function App(localizations) {
  return (
    <MainWrapper>
      <MapWrapped
        localizations={localizations}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAi0LrZ7N8vjd7-7HqbVk0XvPyHU4OBFrE`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </MainWrapper>
  )
}
