import React, { useRef, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import slugify from 'slugify'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'

import colors from 'src/assets/styles/colors'
import distances from 'src/assets/styles/distances'
import typographySizes from 'src/assets/styles/typographySizes'

import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import TitleH1 from 'src/components/global/texts/TitleH1.js'
import TitleH2 from 'src/components/global/texts/TitleH2.js'

import sygnet from 'src/assets/svg/sygnet.svg'
import studioArrow from 'src/assets/svg/studio-arrow.svg'
import mediaQuery from 'src/assets/styles/mediaQuery'
import Seo from 'src/components/global/Seo.js'

import BarText from 'src/components/global/texts/BarText.js'
import gold from 'src/assets/svg/Zlota.svg'
import platinum from 'src/assets/svg/Platynowa.svg'
import diamond from 'src/assets/svg/Diamentowa.svg'
import Map from 'src/components/global/map/Map.js'
import TextAnimationOnStart from 'src/components/global/TextAnimationOnStart.js'

import Slider from 'src/components/global/SliderN.js'

const StyledMain = styled.main`
  width: 100vw;
  background-color: ${colors.black};
  overflow-y: hidden;
`

const HeadrWrappr = styled(MainWrapper)`
  padding-bottom: 120rem;
  padding-top: ${distances.mFromTop}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: calc(${distances.mFromTop}rem + 35rem);
    padding-bottom: 50rem;
  }
`

const Header = styled.header`
  position: relative;
  background-color: ${colors.mainColor};
  overflow-x: hidden;
`
const Title = styled(TitleH1)`
  width: calc(100% - 20rem);
  margin-bottom: 80rem;
  word-wrap: break-word;
  color: ${colors.black};
  z-index: 5;
  margin-left: 20rem;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
    width: calc(100% - 40rem);
    margin-bottom: 30rem;
  }
`

const HeaderBottom = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-wrap: wrap;
  }
`

const HeaderTextCol = styled.div`
  width: 66.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40rem);
    margin-top: 30rem;
  }
`

const HeaderParagraph = styled.p`
  column-count: 2;
  column-gap: 100rem;
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  width: 87.5%;
  margin-left: 20rem;
  @media (max-width: ${mediaQuery.tablet}) {
    column-count: 1;
    width: 100%;
  }
`

const Sygnet = styled.img`
  margin-left: 20rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 50%;
    margin-top: 50rem;
    margin-bottom: 50rem;
  }
`

const HeaderHighlight = styled(BarText)`
  margin-top: 80rem;
  width: 50%;
  font-weight: 700;
  max-width: 530rem;
  text-transform: uppercase;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 30rem;
    max-width: initial;
    width: calc(100% - 20rem);
  }
`

const BottomWrapper = styled(MainWrapper)`
  padding-bottom: ${distances.mBottomPage}rem;
`

const PositionWrapper = styled.div`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  margin-bottom: 10rem;
  :last-of-type {
    margin-bottom: 0rem;
  }
`

const PersonsPositionMobile = styled.div`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  position: relative;
  overflow: hidden;
`

const TeamWrapper = styled.div`
  display: flex;
  padding-top: 50rem;

  padding-bottom: 80rem;
  flex-wrap: wrap;
  &.mobile {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
    flex-direction: column;
    &.mobile {
      display: block;
    }
  }
`

const PositionBox = styled.div`
  width: 16.66%;
  position: relative;
  margin-bottom: 60rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 0rem;
    width: initial;
    height: 45rem;
    display: flex;
    align-items: center;
    background-color: ${colors.secColor};
    display: flex;
    justify-content: space-between;
  }
`

const Teamtitle = styled(BarText)`
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 6rem;
  color: ${props => props.black && colors.black};
  ::before {
    background-color: ${props => props.black && colors.black};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 0rem;
  }
`

const TeamImg = styled(Img)`
  width: calc(100% - 20rem);

  filter: grayscale(1);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 80rem);
    margin-left: 40rem;

    filter: grayscale(0);
  }
`
const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 20rem);
  height: calc(100% - 60rem);
  background-color: ${colors.mainColor};
  mix-blend-mode: screen;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

const TeamNameLabel = styled.div`
  position: absolute;
  transform: rotate(3deg) translate(20rem, -50%);
  padding: 3rem;
  background-color: ${colors.white};
  color: ${colors.black};
  font-size: ${typographySizes.s}rem;
  font-weight: 700;
  max-width: 60%;
  line-height: 1.2;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    max-width: initial;
    display: inline;
    transform: rotate(3deg) translate(20rem, 0%);
  }
`

const TeamBoxLink = styled(Link)`
  width: 16.66%;
  position: relative;
  overflow: hidden;
  padding-bottom: 60rem;
  ${ImageOverlay} {
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    ${TeamNameLabel} {
      transform: rotate(-3deg) translate(20rem, -50%);
      transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ${ImageOverlay} {
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      opacity: 0;
    }
    ${TeamImg} {
      filter: grayscale(0);
      transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    display: block;
    padding-top: 50rem;
    padding-bottom: 0rem;
    overflow: initial;
    :last-of-type {
      padding-bottom: 70rem;
    }
  }
`

const TeamPerson = data => (
  <TeamBoxLink
    to={`/team/${slugify(data.data.Name, {
      lower: true,
      strict: true,
    })}`}
  >
    <TeamImg
      fluid={{
        ...data.data.Images[0].localFile.childImageSharp.fluid,
        aspectRatio: 1,
      }}
    />
    <ImageOverlay />
    <TeamNameLabel>{data.data.Name}</TeamNameLabel>
  </TeamBoxLink>
)

const AwardsSection = styled.div`
  width: 100%;
  height: 60vw;
  /* background-color: firebrick; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: ${mediaQuery.mobile}) {
    display: block;
    height: auto;
  }
`

const AwardsTitle = styled.h3`
  color: ${colors.mainColor};
  font-size: 38vw;
  position: absolute;
  left: 0vw;
  top: 0vw;
  line-height: 0.8;
  z-index: 2;
  @media (max-width: ${mediaQuery.mobile}) {
    position: relative;
  }
`

const AwardsWrapper = styled.div`
  display: flex;
  z-index: 10;
  width: 100%;
  @media (max-width: ${mediaQuery.mobile}) {
    display: block;
    margin-top: 50rem;
  }
`

const Award = styled.div`
  width: 16%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 100%;
  }
  :first-of-type {
    left: 16.66%;
  }
  :nth-last-of-type(2) {
    left: 50%;
    transform: translate(-50%, calc(-50% - 100rem));
  }
  :last-of-type {
    left: 83.33%;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    position: relative;
    transform: translate(0, 0) !important;
    left: 0 !important;
    top: 0;
    width: calc(80% - 80rem);
    margin-left: calc(40rem + 10%);
    margin-bottom: 50rem;
  }
`
const AwardText = styled.span`
  color: ${colors.white};
  font-size: 1vw;
  font-weight: 700;
  position: absolute;
  bottom: 2vw;
  text-align: center;
  width: 100%;
  left: 0;
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: 3.5vw;
    bottom: 8vw;
  }
`

const AwardSmallText = styled(BarText)`
  position: absolute;
  bottom: 3.75vw;
  left: 66.66%;
  font-weight: 700;
  @media (max-width: ${mediaQuery.mobile}) {
    position: relative;
    left: 0rem;
    bottom: 0;
    margin-bottom: 50rem;
  }
`

const MapSection = styled.div`
  margin-top: 70rem;
`

const MapTopRow = styled.div`
  display: flex;
  padding-bottom: 50rem;
  margin-top: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-wrap: wrap;
    padding-bottom: 30rem;
  }
`

const MapTitle = styled(TitleH2)`
  color: ${colors.mainColor};
  margin-left: 20rem;
  width: calc(33.33% - 50rem);
  margin-right: 60rem;
  z-index: 2;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40rem);
    font-size: ${typographySizes.m}rem;
  }
`

const MapParagraph = styled.p`
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  width: calc(33.33% - 50rem);
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 20rem;
    width: calc(100% - 40rem);
    margin-top: 20rem;
  }
`

const LocalizationsTitle = styled(TitleH2)`
  color: ${colors.white};
  margin-left: 20rem;
  width: calc(75% - 20rem);
  padding-top: 50rem;
  padding-bottom: 50rem;
  z-index: 2;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
    width: calc(100% - 40rem);
  }
`

const LocalizationTitle = styled(BarText)`
  font-weight: 700;
  text-transform: uppercase;
`

const StudioEquipment = styled.p`
  font-size: ${typographySizes.m}rem;
  color: ${colors.black};
  width: calc(100% - 40rem);
  margin: 0 20rem;
  text-transform: uppercase;
  line-height: 1.2;
  margin-top: 20rem;
  padding-bottom: 50rem;
  span {
    font-weight: 700;
    font-size: ${typographySizes.m}rem;
    color: ${colors.mainColor};
    text-transform: uppercase;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
    line-height: 1.3;
    span {
      font-size: ${typographySizes.s}rem;
    }
  }
`

const StudioWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: -50rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  /* padding-bottom: 50rem; */
  :first-of-type {
    margin-top: 0rem;
  }
  background-color: ${colors.secColor};
  :nth-of-type(2n) {
    background-color: ${colors.mainColor};
    ${StudioEquipment} {
      color: ${colors.white};
      span {
        color: ${colors.black};
      }
    }
  }

  :hover {
    transform: translateY(-50rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 0rem;
    margin-bottom: 10rem;
    transform: translateY(0rem) !important;
    :hover {
      transform: translateY(0rem);
    }
    :last-of-type {
      margin-bottom: 0rem;
    }
  }
`

const StudioName = styled.h4`
  color: ${colors.black};
  font-size: ${typographySizes.xl}rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  padding-top: 11rem;
  padding: 16rem 20rem 5rem 20rem;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
    padding: 7rem 20rem 5rem 20rem;
  }
`

const StudioAfterText = styled.span`
  font-size: ${typographySizes.s}rem;
  color: ${colors.black};
  font-weight: 700;
  margin-left: -5rem;
  margin-top: 22rem;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

const StudioTobBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
`

const StudioTobText = styled.div`
  display: flex;
  align-items: flex-start;
`

const StudioArrow = styled.img`
  margin-right: 20rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    height: 30rem;
  }
`

const StudioBottom = styled.div`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;
`

const Studio = ({ studio }) => {
  const bottomContent = useRef(null)
  const [bottomH, setBottomH] = useState(0)
  const [curentBottomH, setCurentBottomH] = useState(null)
  const [openBottom, setOpenBottom] = useState(true)

  useEffect(() => {
    const height = bottomContent.current.offsetHeight

    setBottomH(height)

    setTimeout(function () {
      setCurentBottomH(0)
    }, 100)
  }, [bottomContent])

  const studioClick = () => {
    setOpenBottom(openBottom ? false : true)

    openBottom ? setCurentBottomH(bottomH) : setCurentBottomH(0)
  }

  return (
    <StudioWrapper
      style={openBottom ? null : { transform: 'translateY(-50rem)' }}
    >
      <StudioTobBar onClick={studioClick}>
        <StudioTobText>
          <StudioName>{studio.Name}</StudioName>
          <StudioAfterText>(STUDIO)</StudioAfterText>
        </StudioTobText>
        <StudioArrow
          style={openBottom ? null : { transform: 'rotate(-90deg)' }}
          src={studioArrow}
        />
      </StudioTobBar>
      <StudioBottom
        ref={bottomContent}
        style={{
          height: curentBottomH + 'rem',
        }}
      >
        <StudioEquipment>
          {studio.Equipment.map(element => (
            <>
              <span> {element.Type}: </span>
              {element.List}
            </>
          ))}
        </StudioEquipment>
        <Slider images={studio.Images} />
      </StudioBottom>
    </StudioWrapper>
  )
}

const StudiosWrapper = styled.div`
  z-index: 3;
  position: relative;
  margin-top: 50rem;
  padding-bottom: 50rem;
  :last-of-type {
    padding-bottom: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 30rem;
  }
`

const PositionMobile = ({ position }) => {
  const persons = useRef(null)
  const [personsH, setPersonsH] = useState(null)
  const [curentH, setCurentH] = useState(null)
  const [openPersons, setOpenPersons] = useState(true)

  useEffect(() => {
    const height = persons.current.offsetHeight

    setPersonsH(height)

    setTimeout(function () {
      setCurentH(0)
    }, 200)
  }, [persons])

  const positionClick = () => {
    setOpenPersons(openPersons ? false : true)

    openPersons ? setCurentH(personsH) : setCurentH(0)
  }

  return (
    <PositionWrapper>
      <PositionBox onClick={positionClick}>
        <Teamtitle black>{position.node.Position}:</Teamtitle>
        <StudioArrow
          style={openPersons ? null : { transform: 'rotate(-90deg)' }}
          src={studioArrow}
        />
      </PositionBox>
      <PersonsPositionMobile
        ref={persons}
        style={{
          height: curentH + 'rem',
        }}
      >
        {position.node.teams.map(person => (
          <>
            <TeamPerson data={person} />
          </>
        ))}
      </PersonsPositionMobile>
    </PositionWrapper>
  )
}

const Localization = ({ localization }) => {
  // const teamSorted = [...data.strapiArtists.albums].sort(
  //   (a, b) => new Date(b.created_at) - new Date(a.created_at),
  // )

  return (
    <>
      <LocalizationTitle>{localization.Name}</LocalizationTitle>
      <StudiosWrapper>
        {localization.Studio.map(studio => (
          <Studio studio={studio} />
        ))}
      </StudiosWrapper>
    </>
  )
}

const Onas = ({ data }) => {
  let intViewportWidth = 0
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    intViewportWidth = window.innerWidth
  }
  let mobile = isBrowser && intViewportWidth < 900
  return (
    <>
      <Seo
        title={data.strapiPageAbout.SEO ? data.strapiPageAbout.SEO.Title : null}
        description={
          data.strapiPageAbout.SEO ? data.strapiPageAbout.SEO.Description : null
        }
        image={
          data.strapiPageAbout.SEO
            ? data.strapiPageAbout.SEO.Img.localFile.publicURL
            : null
        }
      />
      <StyledMain>
        <Header>
          <HeadrWrappr>
            <Lines leftMargin leftCenter rightMargin />

            <Title>
              {' '}
              <TextAnimationOnStart>
                {data.strapiPageAbout.Big_Intro}
              </TextAnimationOnStart>{' '}
            </Title>
            <HeaderBottom>
              <Sygnet src={sygnet} />
              <HeaderTextCol>
                <HeaderParagraph>
                  {data.strapiPageAbout.Small_Intro}
                </HeaderParagraph>
                <HeaderHighlight>
                  {data.strapiPageAbout.Highlight_Intro}
                </HeaderHighlight>
              </HeaderTextCol>
            </HeaderBottom>
          </HeadrWrappr>
        </Header>
        <BottomWrapper>
          <Lines
            leftMargin
            left
            leftCenter
            center
            rightCenter
            right
            rightMargin
          />
          <TeamWrapper>
            {data.allStrapiTeamPositions.edges.map(position => {
              function compareNumbers(a, b) {
                return a.Order - b.Order
              }
              const teamSorted = position.node.teams

              teamSorted.sort(compareNumbers)

              return (
                <>
                  <PositionBox>
                    <Teamtitle>{position.node.Position}:</Teamtitle>
                  </PositionBox>
                  {teamSorted.map(person => (
                    <>
                      <TeamPerson data={person} />
                    </>
                  ))}
                </>
              )
            })}
          </TeamWrapper>
          <TeamWrapper className="mobile">
            {data.allStrapiTeamPositions.edges.map(position => (
              <PositionMobile position={position} />
            ))}
          </TeamWrapper>
          <AwardsSection>
            <AwardsTitle>PŁY TY</AwardsTitle>
            <ParallaxProvider>
              <AwardsWrapper>
                <Award>
                  <Parallax y={[20, -20]} disabled={mobile}>
                    <img src={gold} alt="złota płyta" />
                    <AwardText>{data.strapiPageAbout.Gold} ZŁOTYCH</AwardText>
                  </Parallax>
                </Award>
                <Award>
                  <Parallax y={[-10, 10]} disabled={mobile}>
                    <img src={diamond} alt="diamentowa płyta" />
                    <AwardText>
                      {data.strapiPageAbout.Diamond} DIAMENTOWYCH
                    </AwardText>
                  </Parallax>
                </Award>
                <Award>
                  <Parallax y={[20, -20]} disabled={mobile}>
                    <img src={platinum} alt="platynowa płyta" />
                    <AwardText>
                      {data.strapiPageAbout.Platinum} PLATYNOWYCH
                    </AwardText>
                  </Parallax>
                </Award>
              </AwardsWrapper>
            </ParallaxProvider>

            <AwardSmallText>
              {data.strapiPageAbout.Awards_Small_Text}
            </AwardSmallText>
          </AwardsSection>
          <MapSection>
            <MapTopRow>
              <MapTitle>
                <TextAnimationOnStart>
                  {data.strapiPageAbout.Localizations_Title}
                </TextAnimationOnStart>
              </MapTitle>

              <MapParagraph>
                {data.strapiPageAbout.Localizations_Paragraph}
              </MapParagraph>
            </MapTopRow>
            <Map localizations={data.allStrapiLocalizations}></Map>
          </MapSection>
          <LocalizationsTitle id="Localizations">
            <TextAnimationOnStart>
              {data.strapiPageAbout.Localizations_SubTitle}
            </TextAnimationOnStart>
          </LocalizationsTitle>
          {data.allStrapiLocalizations.edges.map(localization => (
            <>
              <Localization localization={localization.node} />
            </>
          ))}
        </BottomWrapper>
      </StyledMain>
    </>
  )
}

export const AboutQuery = graphql`
  query AboutQuery {
    strapiPageAbout {
      SEO {
        Description
        Img {
          localFile {
            publicURL
          }
        }
        Title
      }
      Big_Intro
      Highlight_Intro
      Small_Intro
      Gold
      Platinum
      Diamond
      Awards_Small_Text
      Localizations_Paragraph
      Localizations_Title
      Localizations_SubTitle
    }
    allStrapiTeamPositions(sort: { fields: Order, order: ASC }) {
      edges {
        node {
          Position
          Order
          teams {
            Name
            Order
            Images {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 500
                    quality: 100

                    traceSVG: { background: "#000000", color: "#FF3D00" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    allStrapiLocalizations {
      edges {
        node {
          lat
          lng
          Name
          Studio {
            Name
            Equipment {
              List
              Type
            }
            Images {
              height
              width
              localFile {
                childImageSharp {
                  fluid(
                    quality: 100
                    grayscale: true

                    traceSVG: { background: "#000000", color: "#FF3D00" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                colorImg: childImageSharp {
                  fluid(
                    quality: 100

                    traceSVG: { background: "#000000", color: "#FF3D00" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Onas
