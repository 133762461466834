import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import distances from 'src/assets/styles/distances.js'
import mediaQuery from 'src/assets/styles/mediaQuery'
import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'

import Swipeable from 'react-swipeable'

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 50rem;
  width: calc(100% - 20rem);
  padding-left: 20rem;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`
const ImgWrapperMobile = styled(ImgWrapper)`
  display: none;
  position: relative;

  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
  }
`

const StyledImg = styled(Img)`
  width: ${props =>
    props.imgWidth
      ? `calc(${props.imgWidth / props.imgHeight} * 30vw)`
      : '40vw'};
  height: 30vw;
  flex-shrink: 0;
  margin-right: 60rem;

  img {
    object-fit: cover;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    width: ${props =>
      props.imgWidth
        ? `calc(${props.imgWidth / props.imgHeight} * 70vw)`
        : '40vw'};
    height: 70vw;

    margin-right: 30rem;
  }
`

const StyledImgCol = styled(StyledImg)`
  position: absolute !important;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`

const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 60rem);
  height: 100%;
  background-color: ${colors.mainColor};
  mix-blend-mode: screen;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 30rem);
  }
`
const ImageWrapper = styled.div`
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  ${StyledImg}, ${ImageOverlay} {
    transform: rotate(-3deg);
  }
  :nth-of-type(2n) {
    ${StyledImg} {
      transform: rotate(3deg);
    }
    ${ImageOverlay} {
      display: none;
    }
  }
  :nth-of-type(6n) {
    ${StyledImg} {
      transform: rotate(-3deg);
    }
  }
  :hover {
    ${StyledImgCol} {
      opacity: 1;
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`

// const StyledImgMobile = styled(StyledImg)`
//   display: none;
//   @media (max-width: ${mediaQuery.tablet}) {
//     border-right: solid 10px #fff;

//     display: inline-block;
//     width: ${props =>
//       props.imgWidth
//         ? `calc(${props.imgWidth / props.imgHeight} * 50vw)`
//         : '50vw'};
//     height: 50vw;
//   }
// `

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  right: 80rem;
  background-color: ${colors.white};
  padding: 2rem 5rem;

  transform: rotate(3deg);

  @media (max-width: ${mediaQuery.tablet}) {
    right: 20rem;
  }
`

const ShowNext = styled.button`
  font-size: ${typographySizes.s}rem;
  font-weight: 700;
  cursor: pointer;
  margin-left: 50rem;
  text-transform: uppercase;
  opacity: 0.2;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.nondisable {
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.nondisable:hover {
    transform: translateY(-3rem);
  }

  @media (max-width: ${mediaQuery.tablet}) {
  }
`

const ShowPrev = styled.button`
  font-size: ${typographySizes.s}rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0.2;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &.nondisable {
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.nondisable:hover {
    transform: translateY(-3rem);
  }

  @media (max-width: ${mediaQuery.tablet}) {
  }
`
const SliderWrapper = styled.div`
  height: auto;
  position: relative;
  /* width: calc(100vw - ${distances.mainBigMargin}px); */
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: ${mediaQuery.tablet}) {
    overflow: visible;
  }

  /* margin-left: ${distances.mainBigMargin}px; */
`
class Slider extends React.Component {
  state = {
    x: 0,
    curW: 0,
    prevDisable: true,
    nextDisable: false,
    allWidthImg: [],
  }

  componentDidMount() {
    this.props.images.map(document =>
      this.setState(prevState => ({
        allWidthImg: [
          ...prevState.allWidthImg,
          document.width / document.height,
        ],
      })),
    )
  }

  changeSlideNext = () => {
    // this.setState({
    //   x: this.state.x + 1,
    //   curW: this.state.curW + this.state.allWidthImg[this.state.x],
    // })

    this.state.x >= this.props.images.length - 1
      ? this.setState({
          x: this.props.images.length - 1,
          curW: this.state.curW,
        })
      : this.setState({
          x: this.state.x + 1,
          curW: this.state.curW + this.state.allWidthImg[this.state.x],
        })

    this.state.x >= this.props.images.length - 2
      ? this.setState({
          nextDisable: true,
        })
      : this.setState({
          nextDisable: false,
        })

    this.state.x >= 0
      ? this.setState({
          prevDisable: false,
        })
      : this.setState({
          prevDisable: true,
        })
  }

  changeSlidePrev = () => {
    this.state.x <= 0
      ? this.setState({
          x: 0,
          curW: 0,
        })
      : this.setState({
          x: this.state.x - 1,
          curW: this.state.curW - this.state.allWidthImg[this.state.x - 1],
        })

    this.state.x <= 1
      ? this.setState({
          prevDisable: true,
        })
      : this.setState({
          prevDisable: false,
        })

    this.state.x > this.props.images.length - 1
      ? this.setState({
          nextDisable: true,
        })
      : this.setState({
          nextDisable: false,
        })
  }

  swipingLeft = e => {
    this.changeSlideNext()
    e.preventDefault()
  }
  swipingRight = e => {
    this.changeSlidePrev()
    e.preventDefault()
  }

  render() {
    return (
      <Swipeable
        onSwipedLeft={this.swipingLeft}
        preventDefaultTouchmoveEvent={true}
        onSwipedRight={this.swipingRight}
      >
        <SliderWrapper>
          <ImgWrapper sliderWidth={this.props.sliderWidth}>
            {this.props.images.map((document, index) => {
              return (
                <ImageWrapper
                  style={{
                    transform: `translateX(calc(${this.state.curW} * -30vw - ${
                      this.state.x * 60
                    }px))`,
                  }}
                >
                  <StyledImg
                    imgWidth={document.width}
                    imgHeight={document.height}
                    key={index}
                    fluid={document.localFile.childImageSharp.fluid}
                  />

                  <ImageOverlay />
                  <StyledImgCol
                    imgWidth={document.width}
                    imgHeight={document.height}
                    key={index}
                    fluid={document.localFile.colorImg.fluid}
                  />
                </ImageWrapper>
              )
            })}
          </ImgWrapper>
          <ImgWrapperMobile sliderWidth={this.props.sliderWidth}>
            {this.props.images.map((document, index) => {
              return (
                <ImageWrapper
                  style={{
                    transform: `translateX(calc(${this.state.curW} * -70vw - ${
                      this.state.x * 30
                    }px))`,
                  }}
                >
                  <StyledImg
                    imgWidth={document.width}
                    imgHeight={document.height}
                    key={index}
                    fluid={document.localFile.childImageSharp.fluid}
                  />
                  <ImageOverlay />
                </ImageWrapper>
              )
            })}
          </ImgWrapperMobile>
          <ControlWrapper sliderWidth={this.props.sliderWidth}>
            <ShowPrev
              className={this.state.prevDisable ? 'disable' : 'nondisable'}
              onClick={this.changeSlidePrev}
            >
              prev
            </ShowPrev>
            <ShowNext
              className={this.state.nextDisable ? 'disable' : 'nondisable'}
              onClick={this.changeSlideNext}
            >
              next
            </ShowNext>
          </ControlWrapper>
        </SliderWrapper>
      </Swipeable>
    )
  }
}

export default Slider
